import ImageCompare from "image-compare-viewer";

function handleImageSelectionEvent( e ) {
	selectImageForComparison( e.target );
}

function selectImageForComparison( target ) {

	let imageComparison = document.getElementById( 'comparison-container' );

	while (imageComparison.hasChildNodes()) {
		imageComparison.removeChild(imageComparison.lastChild);
	}

	let imageAfter = target.cloneNode();
	imageAfter.className= 'aspect-[3/4]';
	imageComparison.appendChild(imageAfter);

	let imageBefore = target.previousElementSibling;

	if( imageBefore ) {
		imageBefore = target.previousElementSibling.cloneNode();
		imageBefore.className= 'aspect-[3/4]';
		imageComparison.appendChild(imageBefore);
	}


	if( imageBefore ) {
		new ImageCompare( imageComparison, {
		"controlColor": "#cbe6e1",
		"controlShadow": true,
		"addCircle": true,
		"addCircleBlur": true,
		"smoothing": true,
		"smoothingAmount": 100,
		"startingPoint": 10,
		} ).mount();
	}
}

function updateImageListOpacity () {

	let containerRect = comparison_images_container.getBoundingClientRect();

	comparison_images.forEach( image => {

		let imageRect = image.getBoundingClientRect();

		let fullyViewable = imageRect.left >= containerRect.left-3 &&
			            imageRect.right <= containerRect.right+3;

		if( fullyViewable ) {
			image.classList.remove( 'opacity-50' );
		} else {
			image.classList.add( 'opacity-50' );
		}
	} );
}


const comparison_images = document.querySelectorAll( '.comparison-image' );
const comparison_images_container = document.getElementById( 'our-work-images' );


comparison_images.forEach( image => {
	image.addEventListener( 'click', handleImageSelectionEvent );
	image.addEventListener( 'load', updateImageListOpacity );
} );

comparison_images_container.addEventListener( 'scroll', updateImageListOpacity );

selectImageForComparison( comparison_images[0] );



